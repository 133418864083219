
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { TipoComposicao } from '@/core/models/orcamentoObras';
import { TipoComposicaoService } from '@/core/services/orcamentoObras';
 
@Component  
export default class ListaTipoComposicao extends mixins(Vue,ListPage) {
  public service =  new TipoComposicaoService();
  public item = new TipoComposicao();
 
  loading: boolean = false;
  dialogCadastro: boolean = false; 

  titulo: string = 'Tipo da Composição';
  subTitulo: string = 'Tipos das Composições cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };   

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' }
  ];

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
 
    this.service.Listar(page,itemsPerPage,sortBy,['id'],search, columns, undefined, '', '', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }).finally(() => (this.loading = false));
  }
 
 Novo(){
    this.item = new TipoComposicao();
    this.dialogCadastro = true; 
  } 

  mounted(){
   // this.Atualizar();
  }
}
